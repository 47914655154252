import { WhatsApp } from '@mui/icons-material'
import { Button, Divider, Link, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/system'
import { useMemo } from 'react'
import { useUser } from '../../../providers/users/useUser'
import Layout from '../Layout'

export default function Subscriptions() {
  const { user } = useUser()
  const customer_request_url = useMemo(() => {
    return `https://wa.me/5548984960268/?text=Olá, gostaria de solicitar a ativação de um plano de assinatura mensal para a ferramenta de extração do *Rastreador de Processos*, no e-mail *${user?.email}*`
  }, [user])

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        height="calc(100vh - 300px)"
      >
        {!user?.monthly_subscription ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignSelf="center"
            justifySelf="center"
            marginTop="50px"
          >
            <Typography fontSize="1.5rem" fontWeight="bold" marginBottom="20px">
              Plano não habilitado
            </Typography>
            <Typography fontSize="1rem" marginBottom="20px">
              Para iniciar a utilização do sistema de extrações de contato, é
              necessário solicitar a ativação do seu plano de assinatura mensal
            </Typography>

            <Button
              variant="contained"
              size="large"
              color="primary"
              startIcon={<WhatsApp />}
            >
              <Link
                underline="none"
                href={customer_request_url}
                target="_blank"
                color="white"
                fontWeight="bold"
              >
                Solicitar ativação da minha assinatura
              </Link>
            </Button>
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              boxShadow={3}
              width="100%"
              padding="20px"
              borderRadius="10px"
              marginBottom="5px"
              sx={{
                backgroundColor: window.location.hostname.includes('busca')
                  ? '#9553a4'
                  : '#1e1e2d'
              }}
            >
              <Typography fontSize="1.5rem">
                <strong
                  style={{
                    color: window.location.hostname.includes('busca')
                      ? '#000'
                      : '#ef4e6c'
                  }}
                >
                  Plano mensal:
                </strong>{' '}
                <span style={{ color: '#fff' }}>
                  {user.monthly_subscription.pack.name}
                </span>
              </Typography>
              <Typography
                fontSize="1rem"
                marginLeft="auto"
                sx={{
                  borderRadius: '5px',
                  backgroundColor: 'white',
                  padding: '5px 10px'
                }}
              >
                <>
                  créditos disponíveis:{' '}
                  <strong>
                    {new Intl.NumberFormat('pt-BR').format(
                      user.monthly_subscription.available_quota
                    )}
                  </strong>
                </>
              </Typography>
            </Box>
            <Typography
              fontSize="0.8rem"
              marginLeft="auto"
              marginRight="10px"
              sx={{
                borderRadius: '5px',
                backgroundColor: 'white',
                padding: '5px 10px'
              }}
            >
              <>
                créditos disponíveis até{' '}
                <strong>
                  {new Date(
                    user.monthly_subscription.current_quota_due_date
                  ).toLocaleString('pt-BR', {
                    dateStyle: 'long',
                    hour12: false
                  })}
                </strong>
              </>
            </Typography>
            <Box width="100%" padding="5px">
              {user.onetime_subscriptions.length > 0 && (
                <>
                  <Typography
                    fontSize="1.5rem"
                    marginLeft="15px"
                    marginBottom="20px"
                  >
                    <strong>Pacotes avulsos:</strong>
                  </Typography>

                  <Table sx={{ minWidth: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                        >
                          Pacote
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                        >
                          Contratado em
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                        >
                          Data de Validade
                        </TableCell>

                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                        >
                          Créditos disponíveis
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {user.onetime_subscriptions.map((subscription) => (
                        <TableRow
                          key={subscription.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}
                        >
                          <TableCell>{subscription.pack.name}</TableCell>
                          <TableCell>
                            {new Date(
                              user.monthly_subscription.start_date
                            ).toLocaleString('pt-BR', {
                              dateStyle: 'long',
                              hour12: false
                            })}
                          </TableCell>
                          <TableCell>
                            {' '}
                            {new Date(
                              subscription.current_quota_due_date
                            ).toLocaleString('pt-BR', {
                              dateStyle: 'long',
                              hour12: false
                            })}
                          </TableCell>
                          <TableCell>
                            {new Intl.NumberFormat('pt-BR').format(
                              subscription.available_quota
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Divider flexItem />
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    padding="20px"
                    marginTop="30px"
                  >
                    <Typography fontSize="1rem">
                      total de créditos disponíveis:{' '}
                      <strong>
                        {new Intl.NumberFormat('pt-BR').format(
                          user.available_quota
                        )}
                      </strong>
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </Layout>
  )
}
